/**
 * @prettier
 */

document.addEventListener('DOMContentLoaded', function () {
  const flashNotifications = JSON.parse(
    document.querySelector('.js-flash-notifications')?.dataset?.value || '[]',
  )

  if (flashNotifications.length) {
    window.NotificationBar.show(flashNotifications[0][1], flashNotifications[0][0])
  }
})
